/* eslint-disable react/jsx-indent */
import React, { useState } from 'react'
import { graphql } from 'gatsby'
import CompLink from '../components/global/Link'
import Layout from '../components/layout/Layout'
import useAuth from '../util/hooks/useAuth'
import SEO from '../components/SEO'

const NotFoundPage = ({data}) => {
    useAuth();
    const [device, setDevice ] = useState('mobile')
    return (
        <Layout device={device} setDevice={setDevice} footerMenu ={data.footer_menu} locations={data.locations.edges}>
            <SEO noindex={true} title="404 Error - The page you are looking for is not found" />
            <section className="grid-section grid-section-404 grid-content-container">
                <div className="columns">
                    <div className="column is-8">
                        <h1>404 Error</h1>
                        <h3>The page you are looking for is not found</h3>
                        <p>It may have been moved, or removed altogether. Perhaps you can return back to the site’s homepage and see if you can find what you are looking for.</p>
                        <CompLink button className={`btn-large btn-center btn-space-large`} path={"/"} device={device} >Back to Homepage</CompLink>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default NotFoundPage

export const NotFoundQuery = graphql`
query NotFoundQuery {
    allMenuMenuLink(sort: {fields: [weight], order: ASC}, filter: {menu_name: {eq: "gatsby-menu"}}) {
        edges {
            node {
            enabled
            title
            expanded
            external
            langcode
            weight
            link {
                uri
            }
            drupal_parent_menu_item
            bundle
            drupal_id
            menu_name
            }
        }
    }
    footer_menu: allMenuMenuLink(sort: {fields: [weight], order: ASC}, filter: {menu_name: {eq: "footer"}}) {
        edges {
            node {
                enabled
                title
                expanded
                external
                langcode
                weight
                link {
                    uri
                }
                bundle
                drupal_id
                menu_name
            }
        }
    }
    locations: allNodeLocation {
        edges {
          node {
            field_address {
              address_line1
              address_line2
              country_code
              locality
              postal_code
            }
            field_address_description
            field_address_short
            field_location_email
            field_location_fax_number
            field_location_phone_number
            title
            field_office_hours_description
          }
        }
    }
}
`